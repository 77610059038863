import LOGOS from "../../../../assets/logos/index";
import PROFILES from "../../../../assets/profiles/index";

const Kylie = {
    name: "Kylie Dracani",
    position: "Director",
    image1: PROFILES.KylieNewP,
    image2: PROFILES.Kylie2,
    socialNetworks: [
        {url: "https://www.youtube.com/c/kyliedracani", name: "Youtube", image: LOGOS.youtube},
        {url: "https://www.instagram.com/kyliedracani/", name: "Instagram", image: LOGOS.instagram},
        {url: "https://twitter.com/KylieDracani", name: "Twitter", image: LOGOS.twitter},
    ],
    otherNames: "Kylie",
    otherPositions: "Director / Project Leader",
    biography: [
        "カイリー・ドラカニ（Kylie Dracani）はアニメーターや声優として多方面で活躍しているアーティストで、ソフトスカルプチュア（textile sculpture）にも深い造詣がある。また、リーダーとしてプロジェクトの指揮を取ることにも常に積極的である。",
        "ニーヴとの共作の代表作・短編アニメーション『W.A.R.F.』は、チリでの学生時代にカイリーが監督を務めた作品である。この作品は学生作品として初めて世界で20以上の賞を受賞した。",
        "アニメーションの教師であるカイリーは普段、アニメーション分野でのイベントサポートやプロデュースを行っている。その一方フリーの立場で、アニメーションやエンターテイメント分野のプロジェクトに声を提供したり、大企業のケモノ着ぐるみやパペット人形の制作をしたりしている。",
        
    ],
    roleSubtitle: "Forgotten Secondsプロジェクト内での役割",
    role: [
        "『Forgotten seconds』のアイデアは、カイリーが画力向上を目指す中で生まれた 2017年当初。",
        "カイリーの主な役割は、プロジェクトのリーダー兼監督、制作のまとめ役である。タイトルやロゴのデザイン、マンガの文字入れ・ペン入れ・編集作業、オリジナル楽曲の制作、Patreon管理、宣伝・広告、販売促進、出版作業もこなしている。",
    ],
}

export default Kylie;