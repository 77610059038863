const contentEN = {
    lang: "en",
    routingLinks: [
        {
            name: "Home",
            path: "/en"
        }, {
            name: "Read the Manga",
            path: "/en/read"
        }, {
            name: "About",
            path: "/en/about"
        }, {
            name: "Characters",
            path: "/en/characters"
        }, {
            name: "Music",
            path: "/en/music"
        }, {
            name: "Support us",
            path: "/en/support"
        }, {
            name: "Contact",
            path: "/en/contact"
        }
    ],
}

export default contentEN;