import React from 'react';

import "./SocialComponent.sass";

const SocialComponent = ({networks}) => {

    return <div className="social-component">
        {
            networks.map((network, i) => (
                <a key={i} href={network.url} title={network.name}>
                    <img width="40px" height="40px" src={network.image} alt={network.name} />
                </a>
            ))
        }
    </div>
}
 
export default SocialComponent;