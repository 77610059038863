import React from 'react';
import ProfileComponent from "./profile/ProfileComponent";
import { Link } from "react-router-dom";

import "./OurTeamComponent.sass";
import TEXT from "./lang/index";

import useLanguage from '../../../../hooks/useLanguage';
import TitleComponent from '../../../../components/title/TitleComponent';

const OurTeamComponent = () => {

    let translatedText = useLanguage(TEXT).translatedText;

    return <div className="our-team-component">
        {/* <MetatagsComponent metadata={translatedText.metatags} /> */}
        <div className="our-team-component__team-container font-forgotten">

            <div className="our-team-component__team-wrapper">
                <TitleComponent title={translatedText.content.mainTeam.title} lang={useLanguage(TEXT).pathname} />

                <div className="our-team-component__profile-wrapper our-team-component__profile-wrapper-kyliesander">
                    {
                        translatedText.content.mainTeam.members.map((member, i) => (
                            <Link to={member.url} key={i} >
                                <img src={member.picture} alt={member.name} />
                                <ProfileComponent image={member.picture} name={member.name} description={member.description} url={member.url} team="main"  />
                            </Link>
                        ))
                    }
                </div>
            </div>

            <div className="our-team-component__team-wrapper">
                <TitleComponent title={translatedText.content.languageTeam.title} lang={useLanguage(TEXT).pathname} />
                <div className="our-team-component__profile-wrapper">
                    {
                        translatedText.content.languageTeam.members.map((member, i) => (
                            <ProfileComponent key={i} name={member.name} description={member.description} url={member.url} team="language" />
                        ))
                    }
                </div>
            </div>
            
        </div>

    </div>
}
 
export default OurTeamComponent;