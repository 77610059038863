const contentES = {
    lang: "es",
    routingLinks: [
        {
            name: "Inicio",
            path: "/es"
        }, {
            name: "Lee el manga",
            path: "/es/read"
        }, {
            name: "¿Quienes somos?",
            path: "/es/about"
        }, {
            name: "Personajes",
            path: "/es/characters"
        }, {
            name: "Música",
            path: "/es/music"
        }, {
            name: "Donaciones",
            path: "/es/support"
        }, {
            name: "Contacto",
            path: "/es/contact"
        }
    ],
}

export default contentES;