import LOGOS from "../../../../assets/logos/index";
import PROFILES from "../../../../assets/profiles/index";

const Kylie = {
    name: "Kylie Dracani",
    position: "Director",
    image1: PROFILES.KylieNewP,
    image2: PROFILES.Kylie2,
    socialNetworks: [
        {url: "https://www.youtube.com/c/kyliedracani", name: "Youtube", image: LOGOS.youtube},
        {url: "https://www.instagram.com/kyliedracani/", name: "Instagram", image: LOGOS.instagram},
        {url: "https://twitter.com/KylieDracani", name: "Twitter", image: LOGOS.twitter},
    ],
    otherNames: "Kylie",
    otherPositions: "Director / Project Leader",
    biography: [
        "Kylie Dracani is a multifaceted artist, animator and voice actress, with a strong development in the textiles Sculptures. To Direct and Leadership of Projects has always been what she likes to do.",
        "Worked along Nive and Sifyro in their Prime Work, their Animated Short W.A.R.F. she been its  director, W.A.R.F. turns into the first Animated short film made by Chilean students in winning over 20 recognitions around the world.",
        "An Animation Teacher, works constantly assisting and producing events in the Area of Animation.",
        "Also works as an Independent giving her voice to projects in the area of animation and entertainment, also making fursuits and Puppets for very Important Companies."
    ],
    roleSubtitle: "Her role on Forgotten Seconds:",
    role: [
        "The Idea for Forgotten Seconds was born after Kylie’s need to expand her drawing skills in 2017.",
        "Kylie’s main Role is project Leader/Direction and organization of production.",
        "Tasks as of title and logo designs, manga fonts, line art, page text assembly and to be in charge of Patreon, publishing, news, broadcasting, producing original songs, development  and publishing of the manga.",
    ],
}

export default Kylie;