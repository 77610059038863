import React from 'react';

import "./ProfileComponent.sass";

const ProfileComponent = ({image, name, description, url, team}) => {
    return <div className={`profile-component ${"profile-component--"+team}`}>
        <img src={image} alt="profile" className={`profile-component__picture`} />
        <p className={`profile-component__text-name font-seconds ${(team==="language") ? "visible" : "invisible"}`}>{name}</p>
        <div className="profile-component__text-wrapper bg-color-lighter-light">
            <p className={`profile-component__text-name font-seconds ${(team==="main") ? "visible" : "invisible"}`}>{name}</p>
            <div className="profile-component__text-description font-body">
                {
                    description.map((paragraph, i) => (
                        <p key={i} >{paragraph}</p>
                    ))
                }
            </div>
        </div>
    </div>
}

export default ProfileComponent;