import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetatagsComponent = ({metadata}) => {
    return <Helmet>
        <title>{metadata.title}</title>
        <meta property="og:title" content={metadata.title}/>
        <meta property="og:site_name" content={metadata.title}/>
        <meta name="twitter:title" content={metadata.title}/>

        <meta property="description" content={metadata.description} />
        <meta property="og:description" content={metadata.description}/>
        <meta name="twitter:description" content={metadata.description}/>

        <meta property="og:url" content={metadata.url}/>
    </Helmet>
}
 
export default MetatagsComponent;