import PROFILES from "../../../../assets/team/index";

const contentEN = {
    mainTeam: {
        title: "MAIN TEAM",
        mainImage: PROFILES.Team,
        members:[
            {
                picture: PROFILES.NewKylie,
                name: "Kylie Dracani",
                url: "/en/team/Kylie",
                description: ["Project director"]
            },
            {
                picture: PROFILES.NewNive_e,
                name: "Nive_e",
                url: "/en/team/Nive_e",
                description: ["Scriptwriter"]
            },
            {
                picture: PROFILES.NewKate,
                name: "Kate Sander",
                url: "/en/team/Sander",
                description: ["Art director"]
            }
        ]
    },
    languageTeam: {
        title: "LANGUAGE TEAM",
        members: [
            {
                picture: PROFILES.NewBrian,
                name: "Brian Johnson",
                url: "/",
                description: ["English", "Translation and supervisor"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "Maho",
                url: "/",
                description: ["Japanese", "Translation and supervisor"]
            },
        ]
    }
}

export default contentEN;