import LOGOS from "../../../../assets/logos/index";
import PROFILES from "../../../../assets/profiles/index";

const Sander = {
    name: "Sander / Koiyaroid",
    position: "Artist",
    image1: PROFILES.Sander1,
    image2: PROFILES.Sander2,
    socialNetworks: [
        {url: "https://www.youtube.com/channel/UCNqOW7f1zngQlSj5FwriTDg", name: "Youtube", image: LOGOS.youtube},
        {url: "https://www.instagram.com/koiyaroid/", name: "Instagram", image: LOGOS.instagram},
        {url: "https://twitter.com/SanderTheCat", name: "Twitter", image: LOGOS.twitter},
    ],
    otherNames: "Sander // Koiyaroid",
    otherPositions: "Art Director / Main Artist",
    biography: [
        "コイヤロイド（Koiyaroid）は、フリーのアニメーター兼イラストレーターである。幼い頃から独学でキャリアをスタートさせ、18歳でフリーランスとしての活動を始めた。",
        "彼女のアニメ作品は構成や表現が特徴的で、1930～40年代の初期ディズニーや手塚治虫の作品の影響が見られる。イラストレーターとしては、第１回Confuror（メキシコのファーリーコンベンション）のコンブック表紙イラストを、同イベント第２回ではプロモーションイラストを担当した。",
    ],
    roleSubtitle: "Forgotten secondsプロジェクト内での役割",
    role: [
        "2017年のプロジェクト最初期から既にコイヤロイドはプロジェクトに関与していたが、プロジェクトに強い親近感と将来性を感じ、2021年に正式にメインチームに参加。作画や制作進行に大きく貢献している。",
        "マンガ制作プロセスの一つである「下絵」の段階においては、彼女の手が加わることで、すべてのキャラクターに実体が与えられる。",
        "メインアーティストと称されているが、彼女は肩書きにとらわれず様々な仕事をこなす。マンガ制作では作画だけでなく、やりとりが自然になるようセリフ調整に積極的に関わる他、オリジナルグッズのイラストや出版、音響監督アシスタント、アートディレクターやリードアニメーターの役割もこなしている。また、スペイン語版のテルの声は彼女の担当である。",
    ],
}

export default Sander;