import React from 'react';
import { NavLink } from "react-router-dom";
import "./NavComponent.sass";


const NavComponent = ({routingLinks, lang}) => {
    return <ul className="nav-component">
        {
            routingLinks.map(route => (
                <li className="nav-component__link-container" key={route.name}>
                    <NavLink exact to={route.path} activeClassName="nav-component__link--selected" className={`nav-component__link font-seconds bg-color-light font-color-dark ${lang === "jp" ? "jp-font" : ""}`}>{route.name}</NavLink>
                </li>
            ))
        }
    </ul>
}
 
export default NavComponent;