import BUTTONS from "../../../../assets/buttons/index";

const contentJP = {
    kofi: {
        url: "https://forgottenseconds.fanbox.cc/",
        button: BUTTONS.pixivJP
    },
    patreon: {
        url: "https://www.patreon.com/Forgottenseconds",
        button: BUTTONS.patreonJP
    }
}

export default contentJP;