import contentEN from "./en/contentEN";
import contentES from "./es/contentES";
import contentJP from "./jp/contentJP";

const TEXT = {
    en: {
        content: contentEN
    },
    es: {
        content: contentES
    },
    jp: {
        content: contentJP
    }
}

export default TEXT;