import React from 'react';

import "./HelpComponent.sass";

import TitleComponent from '../../../components/title/TitleComponent';
import DonationsComponent from '../../../components/donations/DonationsComponent';


const HelpComponent = ({help, lang}) => {

    return  <div className="help-component">

        <div className="help-component__container">
            <TitleComponent title={ help.title } lang={lang} />
            <a className="help-component__wrapper" href={"/"+ lang +"/support"}>
                <img src={help.button.image} alt="Forgotten Seconds creators help" />
            </a>
            <DonationsComponent link={"/es/support"} />
        </div>

    </div>
}
 
export default HelpComponent;