const getPathname = (pathname) => {
    pathname.indexOf(1);
    pathname.toLowerCase();
    pathname = pathname.split("/")
    pathname.shift()
    pathname.shift()
    pathname = pathname.toString();
    pathname = pathname.replace(",", "/");
    return pathname
}

export default getPathname;