import React, { useEffect } from 'react';
import gsap from 'gsap';

import "./ContactPage.sass";
import TEXT from "./lang/index";

import useLanguage from '../../hooks/useLanguage';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import LinksComponent from '../../components/header/links/LinksComponent';
import TitleComponent from '../../components/title/TitleComponent';

const ContactPage = () => {

    const email = "project@forgottenseconds.com";

    useEffect(() => {
        gsap.set(".contact-page", {opacity: 0})
        gsap.to(".contact-page", {duration: 1, delay: .3, opacity: 1});

        window.scrollTo({
            top: 0,
        });
    }, []);

    const copyToClipboard = (text) => {

        let tl = gsap.timeline();
        tl.set(".contact-page__copied", {y: 0, opacity: 0})
        tl.to(".contact-page__copied", {opacity: 1, y: -30, duration: .5});
        tl.to(".contact-page__copied", {opacity: 0, duration: 1});
        tl.set(".contact-page__copied", {y: 0, opacity: 0})

        const ta = document.createElement("textarea");
        ta.innerText = text;
        document.body.appendChild(ta);
        ta.select();
        document.execCommand("copy");
        ta.remove();
    };

    let translatedText = useLanguage(TEXT).translatedText;

    return <div className="contact-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="contact-page__container bg-color-darker-light">
            <div className="contact-page__wrapper">
                <TitleComponent title={ translatedText.content.title } lang={useLanguage(TEXT).pathname} />
                <div className="contact-page__email-container">
                    <div className="contact-page__email bg-color-lighter-dark font-color-body-light font-seconds">
                        <div className="contact-page__email-text" onClick={() => copyToClipboard(email)}>
                            <p> {email} </p>
                        </div>
                        <LinksComponent />
                    </div>
                </div>
            </div>
        </div>
        <div className={`contact-page__copied bg-color-lighter-light`}>
            <p className="font-color-body-dark font-seconds">Copied!</p>
        </div>
    </div>
}
 
export default ContactPage;