import LOGOS from "../../../../assets/logos/index";
import PROFILES from "../../../../assets/profiles/index";

const Kylie = {
    name: "Kylie Dracani",
    position: "Directora",
    image1: PROFILES.KylieNewP,
    image2: PROFILES.Kylie2,
    socialNetworks: [
        {url: "https://www.youtube.com/c/kyliedracani", name: "Youtube", image: LOGOS.youtube},
        {url: "https://www.instagram.com/kyliedracani/", name: "Instagram", image: LOGOS.instagram},
        {url: "https://twitter.com/KylieDracani", name: "Twitter", image: LOGOS.twitter},
    ],
    otherNames: "Kylie",
    otherPositions: "Directora / Lider de proyecto",
    biography: [
        "Kylie Dracani es una artista multifacética, animadora y actriz de voz, con un fuerte desarrollo en el área de la escultura textil. La dirección y liderazgo de proyectos siempre ha sido lo que más le gusta hacer.",
        "Trabajó junto a Nive Y Sifyro en su opera prima, el cortometraje de animación W.A.R.F., siendo directora de este, W.A.R.F. se convierte en el primer cortometraje de animación de estudiantes en chile en ganar más de 20 reconocimientos alrededor del mundo.",
        "Es profesora de animación y trabaja de forma constante en la asistencia de producción de eventos del área de la animación.",
        "Trabaja de manera independiente dando voz a proyectos del área de la animación y entretención, además confeccionando fursuits y títeres para importantes empresas."
    ],
    roleSubtitle: "Su rol en Forgotten Seconds:",
    role: [
        "La idea de Forgotten Seconds nace de la necesidad de kylie de mejorar sus habilidades de dibujo en el 2017.",
        "El Rol de Kylie es de Líder de proyecto / Dirección y organización de la producción en si.",
        "Tareas como el diseño de logos/ títulos, Font del manga, line art, montaje de textos de las páginas y estar a cargo de patreon, sus publicaciones y avances, algunos dibujos, organizar y producir cada una de las cosas que se comienzan a desarrollar, como las canciones originales, gestión con actores de voz y el desarrollo y publicación del manga en si (y todo lo que eso conlleve).",
    ],
}

export default Kylie;