import MAP from "../../../../assets/map/index";
const contentES = {
    title: "TEASER",
    videoURL: "HRMH9dUjqAY",
    synopsisComponent: {
        title: "SINOPSIS",
        description: [
            'Forgotten Seconds cuenta la histora de dos hermanos husky Teru (19) y Kai (23) que no se ven hace años y tendrán que aprender a abrazar sus diferencias para superar sus conflictos personales.',
            'Una historia sobre superación, introspección y exploración de la identidad.',
        ]
    },
    helpComponent: {
        title: "¡SOLO SOMOS DOS ARTISTAS, NECESITAMOS TU APOYO!",
        button: {
            url: "/es/support",
            image: MAP.esp
        }
    }
}

export default contentES;