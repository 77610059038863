import PROFILES from "../../../../assets/team/index";

const contentES = {
    mainTeam: {
        title: "EQUIPO PRINCIPAL",
        mainImage: PROFILES.Team,
        members:[
            {
                picture: PROFILES.NewKylie,
                name: "Kylie Dracani",
                url: "/es/team/Kylie",
                description: ["Directora del proyecto"]
            },
            {
                picture: PROFILES.NewNive_e,
                name: "Nive_e",
                url: "/es/team/Nive_e",
                description: ["Guionista"]
            },
            {
                picture: PROFILES.NewKate,
                name: "Kate Sander",
                url: "/es/team/Sander",
                description: ["Directora de arte"]
            }
        ]
    },
    languageTeam: {
        title: "EQUIPO DE LENGUAJE",
        members: [
            {
                picture: PROFILES.NewBrian,
                name: "Brian Johnson",
                url: "/",
                description: ["Inglés", "Traducción y supervisor"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "Maho",
                url: "/",
                description: ["Japonés", "Traducción y supervisora"]
            },
        ]
    }
}

export default contentES;