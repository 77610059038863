import MAP from "../../../../assets/map/index";
const contentJP = {
    title: "予告編",
    videoURL: "NAeJX9zbtig",
    synopsisComponent: {
        title: "Forgotten Seconds とは？",
        description: [
            "「Forgotten Seconds -フォーゴットン・セカンズ- 」は、ハスキー犬兄弟のテル（19） とカイ（23）のお話。久しぶりに会った二人はそれぞれ葛藤を抱えながらも、互いの違いを受け入れることを学んでいきます。",
            "これは、アイデンティティを模索し、自己を見つめ、成長していく青年たちの物語です。",
        ]
    },
    helpComponent: {
        title: "たった２人で鋭意制作中！",
        button: {
            url: "/jp/support",
            image: MAP.jpn
        }
    }
}

export default contentJP;