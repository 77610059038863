//0 spanish, 1 english, 2 romaji, 3 multi, 4 japanese
const lyricsLanguages =  [
    [
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "\n",
        "Veo",
        "Todo un mundo nuevo",
        "Esperando por mí, temo",
        "No sé",
        "Si podré lograrlo solo alguna vez",
        "Tengo que ver",
        "\n",
        "Nueva vida y todo a la vez",
        "Siento temor,",
        "Pero voy a hacerlo",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Con algo de fuerza y valor",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Debo seguir hasta lograr…",
        "Ver quién soy",
        "\n",
        "Creo",
        "Que ha olvidado todo",
        "Sobre nuestra niñez, duele",
        "Sé que",
        "Algún día volveremos a entender",
        "Quiénes somos",
        "\n",
        "Nueva vida y todo a la vez",
        "Aquí estaré",
        "Y voy a enfrentarlo",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Con algo de fuerza y valor",
        "Nada va a detenerme ya",
        "\n",
        "Sólo así mi mundo cambiará",
        "Debo seguir hasta lograr…",
        "\n",
        "Esos momentos perdidos van",
        "A regresar",
        "\n",
        "Nueva vida y todo a la vez",
        "Aquí estaré",
        "Y voy a lograrlo",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Con algo de fuerza y valor",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Con algo de fuerza y valor",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Debo seguir hasta lograr",
        "Ver quien soy",
    ],
    [
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "\n",
        "I see",
        "It’s a whole new world out there",
        "It’s waiting for me,",
        "scary",
        "Can’t tell",
        "If I’ll ever make it on my own, alone",
        "Must carry on",
        "\n",
        "New life, new friends, new everything   ",
        "All things I fear",
        "But I’m going to make it ",
        "\n",
        "I see",
        "He’s forgotten all we went through",
        "When we were kids, and hurts me",
        "I know",
        "That the day when we are close again will come",
        "We’ll get along",
        "\n",
        "New life, new friends, new everything",
        "Won’t be alone",
        "And I’m going to make it",
        "\n",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to be brave, need to be strong",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to go on and move along",
        "\n",
        "Those forgotten seconds will return",
        "I know so",
        "\n",
        "New life, new friends, new everything",
        "Here I will be",
        "and I’m going to make it",
        "\n",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to be brave, need to be strong",
        "\n",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to be brave, need to be strong",
        "\n",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to go on and move along",
        "And see who I am",
        "I am",
    ],
    [
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "\n",
        "Soto de atarashii sekai ga matteru　kowai",
        "Hitori ja ikinukeru ka wakaranai　sore demo",
        "\n",
        "Nani mo kamo mae to chigattemo　yarikireru yo!",
        "\n",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Chikara to yuuki wo dashite",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Mae ni susumu shika nai",
        "Boku nari ni",
        "\n",
        "Osanai koro no koto wasureteru ne　tsurai kedo",
        "Kizuna wo naosu hi ga izure kuru no　Shinjiteru",
        "\n",
        "Nani mo kamo mae to chigattemo　Hitori ja nai yo!",
        "\n",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Chikara to yuuki wo dashite",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Mae ni susumu shika nai",
        "\n",
        "Ushinawareta ano suubyou wo tori kaesou!",
        "Ima koko ni iru minna nara　yarikireru yo!",
        "\n",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Chikara to yuuki wo dashite",
        "\n",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Chikara to yuuki wo dashite",
        "Tomerare ya shinai yo",
        "Kono jinsei wo kaenakya",
        "Hontou no jibun wo mitsukenakya",
    ],
    [
        "Tomerare ya shinai yo",
        "止められやしないよ",
        "\n",
        "Kono jinsei wo kaenakya",
        "この人生を変えなきゃ",
        "\n",
        "Soto de atarashii sekai ga matteru　kowai",
        "外で新しい世界が待ってる 怖い",
        "No sé",
        "Si podré lograrlo solo alguna vez",
        "Tengo que ver",
        "\n",
        "New life, new friends, new everything   ",
        "All things I fear",
        "But I’m going to make it ",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Con algo de fuerza y valor",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Debo seguir hasta lograr…",
        "Ver quién soy",
        "\n",
        "Osanai koro no koto wasureteru ne　tsurai",
        "幼い頃のこと忘れてるね　辛い",
        "I know",
        "That the day when we are close again will come",
        "We’ll get along",
        "\n",
        "Nueva vida y todo a la vez",
        "Aquí estaré",
        "¡Y voy a enfrentarlo!",
        "\n",
        "\n",
        "Tomerare ya shinai yo",
        "止められやしないよ",
        "\n",
        "Kono jinsei wo kaenakya",
        "この人生を変えなきゃ",
        "Chikara to yuuki wo dashite",
        "力と勇気を出して",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to go on and move along",
        "\n",
        "Those forgotten seconds will return",
        "I know so",
        "\n",
        "Ima koko ni iru minna nara　yarikireru yo!",
        "\n",
        "Nothing gonna stop me, stop me now",
        "It’s the only way to change my life",
        "Need to be brave, need to be strong",
        "\n",
        "Nada va a detenerme ya",
        "Sólo así mi mundo cambiará",
        "Con algo de fuerza y valor",
        "\n",
        "Tomerare ya shinai yo",
        "止められやしないよ",
        "\n",
        "Kono jinsei wo kaenakya",
        "この人生を変えなきゃ",
        "\n",
        "Hontou no jibun wo mitsukenakya  ",
        "本当の自分を見つけなきゃ",
        "I am",
        "Ver quién soy",
    ],
    [
        "止められやしないよ",
        "この人生を変えなきゃ",
        "\n",
        "外で新しい世界が待ってる 怖い",
        "一人じゃ生き抜けるか分からない　それでも",
        "\n",
        "何もかも前と違っても　やり切れるよ！",
        "\n",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "力と勇気を出して",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "前に進むしかない",
        "僕なりに",
        "\n",
        "幼い頃のこと忘れてるね　辛いけど",
        "絆を治す日がいずれ来るの　信じてる",
        "\n",
        "何もかも前と違っても　一人じゃないよ！",
        "\n",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "力と勇気を出して",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "前に進むしかない",
        "\n",
        "失われたあの数秒を取り返そう！",
        "\n",
        "今ここにいる皆なら　やり切れるよ！",
        "\n",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "力と勇気を出して",
        "\n",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "力と勇気を出して",
        "止められやしないよ",
        "この人生を変えなきゃ",
        "本当の自分を見つけなきゃ",
    ]
]

export default lyricsLanguages;