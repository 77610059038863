import PROFILES from "../../../../../../assets/team/index";

const contentJP = {
    mainTeam: {
        title: "メインチーム",
        mainImage: PROFILES.Team,
        members:[
            {
                picture: PROFILES.KylieFull,
                name: "KYLIEDRACANI",
                url: "/jp/about/Kylie",
                description: ["プロジェクトリーダー、", "その他"]
            },
            {
                picture: PROFILES.SanderFull,
                name: "KOIYAROID",
                url: "/jp/about/Sander",
                description: ["メインアーティスト、", "その他"]
            }
        ]
    },
    languageTeam: {
        title: "文章・翻訳チーム",
        members: [
            {
                picture: PROFILES.NewBrian,
                name: "スペイン語文章",
                url: "/",
                description: ["DRACANI", "KOIYAROID"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "英語翻訳",
                url: "/",
                description: ["DRACANI", "KOIYAROID", "BRIAN JOHNSON", "RODRIGO TRUPP", "CASUAL TOAST"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "日本語翻訳",
                url: "/",
                description: ["SEKKI WOLF"]
            },
        ]
    }
}

export default contentJP;