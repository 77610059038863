const contentEN = {
    title: "READ THE MANGA",
    subtitle: "COMING SOON AT:",
    links: {
        webtoon: "/",
        pixiv: "/",
        artStreet: "/"
    }
}

export default contentEN;