import React from 'react';

import "./TitleComponent.sass";

const TitleComponent = ({title, lang}) => {
    return <p className={`title-component__title bg-color-lighter-dark font-color-body-light font-seconds ${lang === "jp" ? "jp-font" : ""}`}>
        {title}
    </p>
}
 
export default TitleComponent;