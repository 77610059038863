import BUTTONS from "../../../../assets/buttons/index";

const contentES = {
    kofi: {
        url: "https://ko-fi.com/forgottenseconds",
        button: BUTTONS.kofiES
    },
    patreon: {
        url: "https://www.patreon.com/Forgottenseconds",
        button: BUTTONS.patreonES
    }
}

export default contentES;