import React, {useState} from 'react';

const LyricSlider = ({slides, titles}) => {

    const [slideIndex, setSlideIndex] = useState(0)

    const changeSlide = (dir) => {
        
        let numberOfSlides = slides.length - 1;
        let index = slideIndex;
        index+=dir;
        if(index > numberOfSlides) {
            console.log("Max");
            setSlideIndex(0)
        } else if(index < 0) {
            console.log("Min");
            setSlideIndex(numberOfSlides)
        } else {
            setSlideIndex(index)
        }
    } 

    return <div className="lyrics-page__lyrics-container bg-color-light">
        
        <div className="lyrics-page__lyrics-title-container font-seconds">
            <h2>{titles[slideIndex].title1}</h2>
            <h1>{titles[slideIndex].title2}</h1>
            <h2>{titles[slideIndex].title3}</h2>
        </div>
        <div className="lyrics-page__lyrics">
            {
                slides[slideIndex].map((paragraph, i) => (
                    <p className="lyrics-page__paragraph font-body" key={i}>
                        {paragraph}
                    </p>
                ))
            }
        </div>
        <div className="lyrics-page__lyrics-buttons">
            <div className="lyrics-page__lyrics-button lyrics-page__lyrics-button-left font-seconds" onClick={()=>changeSlide(-1)}>{"<"}</div>
            <div className="lyrics-page__lyrics-button lyrics-page__lyrics-button-right font-seconds" onClick={()=>changeSlide(1)}>{">"}</div>
        </div>
    </div>
}
 
export default LyricSlider;