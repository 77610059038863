import React from 'react';
import "./LangPicker.sass";

import { useHistory, useLocation } from "react-router-dom";
import getPathname from '../../../functions/getPathname';

const LangPicker = () => {

    let location = useLocation();
    let history = useHistory();

    const changeLanguage = (lang) => {
        let pathname = location.pathname;
        if(!pathname) {
            pathname = "";
            history.replace("/"+lang);
            return
        }
        pathname = getPathname(pathname)
        history.replace("/"+lang+"/" + pathname);
    }

    return <div className="lang-picker-component">
        <button onClick={() => changeLanguage("jp")}>JP</button>
        <button onClick={() => changeLanguage("en")}>EN</button>
        <button onClick={() => changeLanguage("es")}>ES</button>
        <a href="/es">a</a>
        <a href="/jp">a</a>
        <a href="/en">a</a>
        <a href="/es/exclusive">a</a>
        <a href="/jp/exclusive">a</a>
        <a href="/en/exclusive">a</a>
    </div>
}
 
export default LangPicker;