import LOGOS from "../../../../assets/logos/index";
import PROFILES from "../../../../assets/profiles/index";

const Sander = {
    name: "Sander / Koiyaroid",
    position: "Artist",
    image1: PROFILES.Sander1,
    image2: PROFILES.Sander2,
    socialNetworks: [
        {url: "https://www.youtube.com/channel/UCNqOW7f1zngQlSj5FwriTDg", name: "Youtube", image: LOGOS.youtube},
        {url: "https://www.instagram.com/koiyaroid/", name: "Instagram", image: LOGOS.instagram},
        {url: "https://twitter.com/SanderTheCat", name: "Twitter", image: LOGOS.twitter},
    ],
    otherNames: "Sander // Koiyaroid",
    otherPositions: "Art Director / Main Artist",
    biography: [
        "Koiyaroid is an Independent animator and drawer, Since very little She initiated her career self taught, she’s been working as a freelancer since she was 18.",
        "Her work’s Highlights are structure and expression in each of them, the influences are early Disney Productions from the 30’s and 40’s and the Works of Osamu Tezuka.",
        "She has been in Charge of the Illustration of the first edition’s cover of Conbook from Confuror and in its second edition, she was in charge of the promotional  Illustrations for the same event.",
    ],
    roleSubtitle: "Her role on Forgotten Seconds:",
    role: [
        "Sander was already working on the Project since its beginnings in 2017, but she makes it officially to the creative and directive team in 2021, after the feel of great affinity and believing on Forgotten Seconds.",
        "She gets the principal Authority Artist title, but in truth , she does much more, with the Manga, she revises and turns all characters into models, under the process method of “name.",
        "Besides the design and redesign of Characters, creatively proposing and assisting with dialog adjusting, allowing it to be more Natural.",
        "Also, she illustrates original Merchandise, publishing, director assistant in voicing and participates as; Arts director and Lead Animator.",
        "She is also the official voice of Teru for the Spanish version.",
    ],
}

export default Sander;