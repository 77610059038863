import BUTTONS from "../../../../assets/buttons";

const contentEN = {
    title: "Support Us",
    kofiTitle: "KO-FI",
    kofiSubtitle: "Direct Donations",
    buttons: {
        kofi: BUTTONS.kofi,
        patreon: BUTTONS.patreon,
        pixiv: BUTTONS.pixivJP
    },
    koFiDescription: [
        "If you want to Support our Project, click the Button and make your donation directly. you must have an account in KOFI",
    ],
    koFiLink: {
        text: "Click here to donate",
        url: "https://ko-fi.com/forgottenseconds"
    },

    patreonTitle: "Patreon",
    patreonSubtitle: "Join our Patreon and obtain rewards!",
    patreonDescription: [
        "Join our Patreon and get rewards for sponsoring us by Joining you will be donating month to month automatically the amount that you desire to the project, in exchange you’ll be rewarded exclusively!",
        "You may end your Sponsorship whenever you desire.",
    ],
    patreonLink: {
        text: "Check the benefits here",
        url: "https://www.patreon.com/Forgottenseconds",
        pixivText: "pixivFANBOX(ファンボックス) 特典の詳細はこちら",
        pixivURL: "https://forgottenseconds.fanbox.cc"
    },
    
    description: [
        "Forgotten Seconds project is possible thanks to the Donations, only thanks to you we are able to pay the Artists of; Drawing, animators, Musicians and translators for the wonderful job they do for Forgotten Seconds.",
        "We are a team of Two people and our dream  is to be able to Live out of this, we are always thankful for your contributions!",
        "If you can't support us with money and really want to help us, remember that you can always share the Project, our videos and publications on your own Social Media and Friends, this will Infinitely help us.",
        "Thank you!"
    ],

    team: "Koiyaroid, Dracani"
}

export default contentEN;