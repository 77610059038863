import MUSIC from "../../../../assets/music/index";

const contentJP = {
    title: "音楽",
    subtitle: "COMING SOON AT:",
    links: {
        youtube: "/",
        spotify: "/",
    },
    title2: "楽曲再生",
    songTitle: '「本当の自分」',
    albumCover: MUSIC.jpn,
    globo: {
        img: MUSIC.globoJPN,
        url: "/"
    },
    buttons: {
        video: {
            text: "MV",
            url: "/"
        },
        lyrics: {
            text: "楽曲再生"
        }
    },
    textInfo: [
        "プロジェクト全体のテーマソングで、劇中のテルの心情も表しています。",
        "作曲：KAMIGT",
        "歌詞：Inger Marinkovic",
        "日本語版歌詞：Casual Toast",
        "\n",
        "日本語・英語・スペイン語、および複合版を製作しました。",
        "日本語- Casual Toast",
        "英語- Caleb Hyles",
        "スペイン語- César franco",
        "\n",
        "リリース日:2021/08/27"
    ],
}

export default contentJP;