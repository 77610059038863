const contentES = {
    title: "LEE EL MANGA",
    subtitle: "PRÓXIMAMENTE EN:",
    links: {
        webtoon: "/",
        pixiv: "/",
        artStreet: "/"
    }
}

export default contentES;