import React from 'react';

import "./SynopsisComponent.sass";

import TitleComponent from '../../../components/title/TitleComponent';


const SynopsisComponent = ({synopsisText, lang}) => {

    return  <div className="synopsis-component">

        <div className="synopsis-component__text-container">
            <TitleComponent title={ synopsisText.title } lang={lang} />
            <div className="synopsis-component__text-wrapper bg-color-lighter-light">
                {
                    synopsisText.description.map((paragraph, i) => (
                        <p className="synopsis-component__paragraph font-body" key={i}>
                            {paragraph}
                        </p>
                    ))
                }
            </div>
        </div>

    </div>
}
 
export default SynopsisComponent;