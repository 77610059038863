import React from 'react';
import SupportComponent from '../support/SupportComponent';

import "./FooterComponent.sass"

const FooterComponent = () => {
    return <footer className="footer-component">
        <p className="footer-component__text-big font-color-lighter-light font-forgotten">FORGOTTEN SECONDS ®, 2022</p>
        <p className="footer-component__text-small font-color-lighter-light font-forgotten">- Site developed by tagz4everyone@gmail.com -</p>
        <SupportComponent />
    </footer>
}
 
export default FooterComponent;