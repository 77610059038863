import React, { useEffect } from 'react';

import gsap from 'gsap';

import "./SupportPage.sass";

import TEXT from "./lang/index";
import useLanguage from '../../hooks/useLanguage';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';

import LOGOS from "../../assets/logos/index";
import SCHOOL from "../../assets/school.webp";


const SupportPage = () => {

    let translatedText = useLanguage(TEXT).translatedText; //Custom Hook
    let languageUsed = useLanguage(TEXT).pathname;

    useEffect(() => {
        //Fade In Transition effect
        gsap.set(".support-page", {opacity: 0})
        gsap.to(".support-page", {duration: 1, delay: .3, opacity: 1});
        window.scrollTo({
            top: 0,
        });
    }, [])

    return  <div className="support-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="support-page__text-container">
            <TitleComponent title={ translatedText.content.title } lang={languageUsed} />
            <div className="support-page__text-wrapper">
                <div className="support-page__donation-buttons">
                    <a href={translatedText.content.koFiLink.url}><img className="support-page__donation-button" src={translatedText.content.buttons.kofi} alt="Ko-Fi Donation Button" /></a>
                    <a href={translatedText.content.patreonLink.url}><img className="support-page__donation-button" src={translatedText.content.buttons.patreon} alt="Patreon Donation Button" /></a>
                    <a href={translatedText.content.patreonLink.pixivURL}><img className={`support-page__donation-button ${languageUsed === "jp" ? "" : "support-page--not-visible"}`} src={translatedText.content.buttons.pixiv} alt="Pixiv Donation Button" /></a>
                </div>

                <img className="support-page__school-image" src={SCHOOL} alt="Furry characters sitting in a desk" />

                <div className="support-page__donation-options font-body">

                    <div className="support-page__kofi">
                        <a href={translatedText.content.koFiLink.url}><img className="support-page__kofi-icon" src={LOGOS.kofi} alt="Ko-Fi Icon" width="100" height="100" /></a>
                        <div className="support-page__kofi-text">
                            <div className="support-page__kofi-titles">
                                <p className="support-page__title">{translatedText.content.kofiTitle}</p>
                                <p className="support-page__subtitle">{translatedText.content.kofiSubtitle}</p>
                                <div className="support-page__description">{
                                    translatedText.content.koFiDescription.map((paragraph, i) => (
                                        <p className="support-page__paragraph font-body" key={i}>
                                            {paragraph}
                                        </p>
                                    ))
                                }</div>
                            </div>
                        </div>
                    </div>

                    <div className="support-page__patreon">
                        <a href={translatedText.content.patreonLink.url}><img className="support-page__patreon-icon" src={LOGOS.patreon} alt="Patreon Icon" width="100" height="100" /></a>
                        <div className="support-page__patreon-text">
                            <div className="support-page__patreon-titles">
                                <p className="support-page__title">{translatedText.content.patreonTitle}</p>
                                <p className="support-page__subtitle">{translatedText.content.patreonSubtitle}</p>
                            </div>
                            <div className="support-page__description">{
                                translatedText.content.patreonDescription.map((paragraph, i) => (
                                    <p className="support-page__paragraph font-body" key={i}>
                                        {paragraph}
                                    </p>
                                ))
                            }
                            </div>
                            <a href={translatedText.content.patreonLink.url}> {translatedText.content.patreonLink.text} </a>
                            <br></br>
                            <a className={`${languageUsed === "jp" ? "" : "support-page--not-visible"}`} href={translatedText.content.patreonLink.pixivURL}> {translatedText.content.patreonLink.pixivText} </a>
                        </div>
                    </div>

                    <div className="support-page__main-description">
                        {
                            translatedText.content.description.map((paragraph, i) => (
                                <p className="support-page__paragraph font-body" key={i}>
                                    {paragraph}
                                </p>
                            ))
                        }
                    </div>
                    
                    <p className="support-page__team">{translatedText.content.team}</p>

                </div>
            </div>
        </div>
    </div>
}
 
export default SupportPage;