import { useLocation } from 'react-router';

export default function useLanguage(languageIndexFile) {

    let location = useLocation();
    let pathname = location.pathname;
    pathname.indexOf(1);
    pathname.toLowerCase();
    pathname = pathname.split("/")[1];

    let translatedText = languageIndexFile.en;

    if(pathname === "en") {
        if(languageIndexFile.en)
            translatedText = languageIndexFile.en;
        else
            console.error("There is not an english file for this page");
    } else if(pathname === "es") {
        if(languageIndexFile.es)
            translatedText = languageIndexFile.es;
        else
            console.error("There is not a spanish file for this page");
    } else if(pathname === "jp") {
        if(languageIndexFile.jp)
            translatedText = languageIndexFile.jp;
        else
            console.error("There is not a japanese file for this page");
    } else {
        //English by default
        if(languageIndexFile.en)
            translatedText = languageIndexFile.en;
        else
            console.error("There is not an default english file for this page");
    }

    const results = {translatedText, pathname}

    return results;

}