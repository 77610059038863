const LOGOS = {
    discord: require("./discord.webp").default,
    facebook: require("./facebook.webp").default,
    instagram: require("./instagram.webp").default,
    kofi: require("./kofi.webp").default,
    patreon: require("./patreon.webp").default,
    spotify: require("./spotify.webp").default,
    spotifyBig: require("./spotifybig.webp").default,
    tumblr: require("./tumblr.webp").default,
    twitter: require("./twitter.webp").default,
    youtube: require("./youtube.webp").default,
    youtubeBig: require("./youtubebig.webp").default,
    donations: require("./donations.webp").default,
    donationsES: require("./donaciones.webp").default,
    donationsJP: require("./donationsjp.png").default,
}

export default LOGOS;