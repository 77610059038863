import React from 'react';
import "./LinksComponent.sass";

import LOGOS from "../../../assets/logos/index";

const links = [
    {
        name: "Youtube",
        path: "https://www.youtube.com/c/ForgottenSeconds",
        logo: LOGOS.youtube
    },
    // {
    //     name: "Spotify",
    //     path: "/",
    //     logo: LOGOS.spotify
    // },
    {
        name: "Instagram",
        path: "https://www.instagram.com/forgotten.seconds/",
        logo: LOGOS.instagram
    }, {
        name: "Facebook",
        path: "https://www.facebook.com/ForgottenSeconds",
        logo: LOGOS.facebook
    }, {
        name: "Twitter",
        path: "https://twitter.com/Forgottnseconds",
        logo: LOGOS.twitter
    }, {
        name: "Tumblr",
        path: "https://forgottenseconds.tumblr.com/",
        logo: LOGOS.tumblr
    },
    // {
    //     name: "Discord",
    //     path: "/",
    //     logo: LOGOS.discord
    // }
]

const LinksComponent = () => {
    return <ul className="links-component">
        {
            links.map(route => (
                <li className="links-component__link" key={route.name}>
                    <a href={route.path} title={route.name} className="link-color-light font-size-m">
                        <img src={route.logo} alt={route.name} width="33px" height="33px" />
                    </a>
                </li>
            ))
        }
    </ul>
}
 
export default LinksComponent;