import LOGOS from "../../../assets/logos/index";

const TEXT = {
    en: {
        content: {
            url: "/en/support",
            button: LOGOS.donations
        }
    },
    es: {
        content: {
            url: "/es/support",
            button: LOGOS.donationsES
        }
    },
    jp: {
        content: {
            url: "/jp/support",
            button: LOGOS.donationsJP
        }
    }
}

export default TEXT;