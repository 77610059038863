import React from 'react';

import TEXT from "./lang/index";
import useLanguage from '../../hooks/useLanguage';

const SupportComponent = () => {

    let translatedLinks = useLanguage(TEXT).translatedText; //Custom Hook

    return <div className="support-component">
        <a href={translatedLinks.content.kofi.url}><img className="support-component__donation-button" src={translatedLinks.content.kofi.button} alt="Ko-Fi Donation Button" /></a>
        <a href={translatedLinks.content.patreon.url}><img className="support-component__donation-button" src={translatedLinks.content.patreon.button} alt="Patreon Donation Button" /></a>
    </div>
}
 
export default SupportComponent;