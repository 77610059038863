import React, { useEffect } from 'react';
import "./ExclusivePage.sass";

import TEXT from "./lang/index";
import useLanguage from '../../hooks/useLanguage';

import MetatagsComponent from "../../components/metatags/MetatagsComponent";
import TitleComponent from '../../components/title/TitleComponent';

const ExclusivePage = () => {

    let translatedText = useLanguage(TEXT).translatedText;

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    return <div className="exclusive-page bg-color-darker-light">
        <MetatagsComponent metadata={translatedText.metatags} />

        <div className="exclusive-page__container">
            <TitleComponent title={translatedText.content.title} lang={useLanguage(TEXT).pathname}/>
            <div className="exclusive-page__video-container">
                <div className="exclusive-page__video-wrapper">
                    {/* Recordatorio: Cuidado con las REACTS conventions */}
                    <iframe
                        width="560"
                        height="315"
                        
                        title="YouTube exclusive video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${translatedText.content.videoURL}?autoplay=1><img src=https://img.youtube.com/vi/${translatedText.content.videoURL}/hqdefault.jpg alt='Forgotten Seconds Exclusive'><span>▶</span></a>`}
                        ></iframe>
                </div>
            </div>
        </div>

    </div>
}
 
export default ExclusivePage;