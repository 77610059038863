import React, { useEffect } from 'react';
import gsap from 'gsap';

import TEXT from "./lang/index";

import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';
import useLanguage from '../../hooks/useLanguage';

import "./CharactersPage.sass";


const CharactersPage = () => {

    useEffect(() => {
        gsap.set(".characters-page", {opacity: 0})
        gsap.to(".characters-page", {duration: 1, delay: .3, opacity: 1});
        window.scrollTo({
            top: 0,
        });
    }, [])

    let translatedText = useLanguage(TEXT).translatedText;

    return <div className="characters-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="characters-page__block-container">
            <TitleComponent title={translatedText.content.title} lang={useLanguage(TEXT).pathname} />
            <div className="characters-page__block-wrapper bg-color-lighter-dark">
                <p className="characters-page__title font-color-light font-seconds"> {translatedText.content.subtitle} </p>
                <div className="characters-page__logos">

                </div>
            </div>
        </div>
    </div>
}
 
export default CharactersPage;