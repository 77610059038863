import BUTTONS from "../../../../assets/buttons";

const contentES = {
    title: "Apóyanos",
    kofiTitle: "KO-FI",
    kofiSubtitle: "Donaciones directas",
    buttons: {
        kofi: BUTTONS.kofiES,
        patreon: BUTTONS.patreonES,
        pixiv: BUTTONS.pixivJP
    },
    koFiDescription: [
        "Si quieres apoyar nuestro proyecto, puedes clickear el botón y realizar tu donación directamente. debes tener una cuenta en KO-FI",
        "¡Es un sitio completamente seguro!"
    ],
    koFiLink: {
        text: "Haz click aquí para donar",
        url: "https://ko-fi.com/forgottenseconds"
    },

    patreonTitle: "Patreon",
    patreonSubtitle: "¡Únete a nuestro Patreon y recibe recompensas por patrocinarnos!",
    patreonDescription: [
        "Al unirte, estarás donando mes a mes automáticamente la suma que tu desees al proyecto, a cambio: ¡recibirás recompensas exclusivas!",
        "¡Podrás cancelar tu patrocinio cuando tu desees!",
    ],
    patreonLink: {
        text: "Conoce los beneficios aquí",
        url: "https://www.patreon.com/Forgottenseconds",
        pixivText: "pixivFANBOX(ファンボックス) 特典の詳細はこちら",
        pixivURL: "https://forgottenseconds.fanbox.cc"
    },
    
    description: [
        "El proyecto Forgotten Seconds se está realizando gracias a las donaciones, sólo gracias a ustedes podemos pagarle a artistas de dibujo, animadores, músicos y traductores por el maravilloso trabajo que hacen por Forgotten Seconds.",
        "Somos un equipo de 2 personas y nuestro sueño es poder vivir algún día de esto, estaremos siempre muy agradecidos con tu aporte!",
        "Si no puedes apoyarnos con dinero y realmente quieres ayudarnos, recuerda que siempre puedes compartir el proyecto, nuestros videos y publicaciones en tus redes sociales y con tus amigos , eso nos ayudaría infinitamente!",
        "¡Muchas gracias!"
    ],

    team: "Koiyaroid, Dracani"
}

export default contentES;