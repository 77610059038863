const contentJP = {
    title: "マンガを読む",
    subtitle: "COMING SOON AT:",
    links: {
        webtoon: "/",
        pixiv: "/",
        artStreet: "/"
    }
}

export default contentJP;