import BUTTONS from "../../../../assets/buttons";

const contentJP = {
    title: "ご支援のお願い",
    kofiTitle: "KO-FI",
    kofiSubtitle: "直接のご寄付",
    buttons: {
        kofi: BUTTONS.kofiJP,
        patreon: BUTTONS.patreonJP,
        pixiv: BUTTONS.pixivJP
    },
    koFiDescription: [
        "私たちのプロジェクトを支援してくださる方は、ボタンをクリックして寄付をお願いします。 KO-FIのアカウントが必要です",
    ],
    koFiLink: {
        text: "Click here to donate",
        url: "https://ko-fi.com/forgottenseconds"
    },

    patreonTitle: "Patreon and Pixivfanbox",
    patreonSubtitle: "パトロン/ pixivFANBOX(ファンボックス) 特 典付き",
    patreonDescription: [
        "毎月自動で定額寄付していただく方法です。寄付金額は自由に決めることができます。支援のお礼としてパトロンだけの特典を用意しております。定額寄付は、いつでも停止することができます。",
    ],
    patreonLink: {
        text: "パトロン 特典の詳細はこちら",
        url: "https://www.patreon.com/Forgottenseconds",
        pixivText: "pixivFANBOX(ファンボックス) 特典の詳細はこちら",
        pixivURL: "https://forgottenseconds.fanbox.cc"
    },
    
    description: [
        "Forgotten secondsプロジェクトは、皆様からのご寄付によって成り立っています。皆様のご寄付のおかげで、Forgotten secondsのために尽力してくださっているイラストレーター、アニメーター、音楽アーティスト、翻訳者の皆様に対価を支払うことができています。",
        "私たち3人のチームは、このプロジェクトを生業とすることを目指しています。皆さんの寄付にいつも感謝しています。",
        "上記の方法でなくても、皆様がこのプロジェクトや、動画、マンガをＳＮＳでシェアしたり友達に広めたりしていただければ、それだけで絶大な支援になりますので、是非よろしくお願いいたします。",
        "最後までお読みいただきありがとうございます。"
    ],

    team: "チーム Koiyaroid, Dracani"
}

export default contentJP;