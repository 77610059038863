import React, { useEffect } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';

import TEXT from "./lang/index";

import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';
import useLanguage from '../../hooks/useLanguage';

import LOGOS from "../../assets/logos/index";
import MUSIC from "../../assets/music/index";

import "./MusicPage.sass";


const MusicPage = () => {

    useEffect(() => {
        gsap.set(".music-page", {opacity: 0})
        gsap.to(".music-page", {duration: 1, delay: .3, opacity: 1});

        window.scrollTo({
            top: 0,
        });
    }, [])

    let translatedText = useLanguage(TEXT).translatedText;
    let languageUsed = useLanguage(TEXT).pathname;

    return <div className="music-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="music-page__block-container">
            <TitleComponent title={translatedText.content.title} lang={languageUsed} />
            <div className="music-page__block-wrapper bg-color-lighter-dark">
                <div className="music-page__song-info-wrapper">
                    <div className="music-page__song-info-one">
                        <div className="music-page__album-cover bg-color-light">
                            <img src={translatedText.content.albumCover} alt="Forgotten Seconds Album Cover"/>
                        </div>
                    </div>
                    <div className="music-page__song-info-two">
                        <div className="music-page__song-title bg-color-light">
                            <h1 className={`font-seconds ${languageUsed === "jp" ? "jp-font" : ""}`}>{translatedText.content.songTitle}</h1>
                        </div>
                        <div className="music-page__song-description bg-color-light font-body">
                            {
                                translatedText.content.textInfo.map((paragraph, i) => (
                                    <p className="music-page__paragraph font-body" key={i}>
                                        {paragraph}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="music-page__teru-container">
                    <div className="music-page__logos-top">
                        <a href="https://www.youtube.com/c/ForgottenSeconds"><img src={LOGOS.youtubeBig} alt="Forgotten Seconds music on youtube" /></a>
                        <a href="https://open.spotify.com/artist/2lGvrBCW28CCilwfaCkBpp"><img src={LOGOS.spotifyBig} alt="Forgotten Seconds music on spotify" /></a>
                    </div>
                    <div className="music-page__teru-wrapper">
                        <img className="music-page__teru" src={MUSIC.teru} alt="Teru Bandcamp" />
                        <a href="https://forgottenseconds.bandcamp.com/releases">
                            <img className="music-page__globo" src={translatedText.content.globo.img} alt="Teru Bandcamp" />
                        </a>
                    </div>
                </div>

                <div className="music-page__buttons font-seconds">
                    <a href="https://youtube.com/playlist?list=PL8IVLkUPWTFDevqpYTZl939NXbetwJrn7" className='music-page__button bg-color-light'>
                        <h1 className="bg-color-light">{translatedText.content.buttons.video.text}</h1>
                    </a>
                    <Link to={"/"+languageUsed+"/music/lyrics"} className='music-page__button bg-color-light'>
                        <h1 className="bg-color-light">{translatedText.content.buttons.lyrics.text}</h1>
                    </Link>
                </div>

                <div style={{height: "35px"}}></div>
                
                <div className="music-page__logos-bottom disappear">
                    <a href="https://www.youtube.com/c/ForgottenSeconds"><img src={LOGOS.youtubeBig} alt="Forgotten Seconds music on youtube" /></a>
                    <a href="https://open.spotify.com/artist/2lGvrBCW28CCilwfaCkBpp"><img src={LOGOS.spotifyBig} alt="Forgotten Seconds music on spotify" /></a>
                </div>
            </div>
        </div>
        <TitleComponent title={translatedText.content.title2} lang={useLanguage(TEXT).pathname} />
        <div className="music-page__block-container">
            <div className="music-page__block-wrapper bg-color-lighter-dark">
                <div className="music-page__playlist">
                    <iframe src="https://open.spotify.com/embed/album/6BI5NtI0Wn7p7CeVu2nJ1W?theme=0" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media" title="Forgotten Seconds Playlist"></iframe>
                </div>
                <div className="music-page__logos-bottom">
                    <a href="https://www.youtube.com/c/ForgottenSeconds"><img src={LOGOS.youtubeBig} alt="Forgotten Seconds music on youtube" /></a>
                    <a href="https://open.spotify.com/artist/2lGvrBCW28CCilwfaCkBpp"><img src={LOGOS.spotifyBig} alt="Forgotten Seconds music on spotify" /></a>
                </div>
            </div>
        </div>
    </div>
}
 
export default MusicPage;