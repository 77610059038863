import contentEN from "./en/contentEN";
import contentJP from "./jp/contentJP";
import contentES from "./es/contentES";
import metatagsEN from "./en/metatagsEN";
import metatagsES from "./es/metatagsES";

const TEXT = {
    en: {
        content: contentEN,
        metatags: metatagsEN
    },
    es: {
        content: contentES,
        metatags: metatagsES
    },
    jp: {
        content: contentJP,
        metatags: metatagsEN
    }
}

export default TEXT;