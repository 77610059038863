import metatagsEN from "./en/metatagsEN";
import contentEN from "./en/contentEN";

import contentJP from "./jp/contentJP";
import metatagsJP from "./jp/metatagsJP";

import metatagsES from "./es/metatagsES";
import contentES from "./es/contentES";

const TEXT = {
    en: {
        metatags: metatagsEN,
        content: contentEN
    },
    es: {
        metatags: metatagsES,
        content: contentES
    },
    jp: {
        metatags: metatagsJP,
        content: contentJP,
    }
}

export default TEXT;