const BUTTONS = {
    kofi: require("./kofi.webp").default,
    patreon: require("./patreon.webp").default,
    kofiES: require("./kofies.webp").default,
    patreonES: require("./patreones.webp").default,
    kofiJP: require("./kofijp.png").default,
    patreonJP: require("./patreonjap.webp").default,
    pixivJP: require("./pixiv.webp").default,
}

export default BUTTONS;