const contentJP = {
    button: "< ディスコグラフィーに戻る",
    mainTitle: "歌詞",
    titles: [
        {
            title1: '"New Everything"',
            title2: "Todo a la vez",
            title3: "Español"
        },
        {
            title1: '"New Everything',
            title2: "English",
            title3: ""
        },
        {
            title1: '"New Everything"',
            title2: "「本当の自分」",
            title3: "Romaji"
        },
        {
            title1: '"New Everything"',
            title2: "Multilanguage",
            title3: ""
        },
        {
            title1: '"New Everything"',
            title2: "「本当の自分」",
            title3: ""
        },
    ]
}

export default contentJP;