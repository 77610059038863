import BUTTONS from "../../../../assets/buttons/index";

const contentEN = {
    kofi: {
        url: "https://ko-fi.com/forgottenseconds",
        button: BUTTONS.kofi
    },
    patreon: {
        url: "https://www.patreon.com/Forgottenseconds",
        button: BUTTONS.patreon
    }
}

export default contentEN;