const MUSIC = {
    eng: require("./ENG.png").default,
    esp: require("./ESP.png").default,
    jpn: require("./JPN.png").default,
    globoENG: require("./globoENG.png").default,
    globoESP: require("./globoESP.png").default,
    globoJPN: require("./globoJPN.png").default,
    teru: require("./teru.png").default
}

export default MUSIC;