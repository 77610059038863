import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import "./SideNavComponent.sass";

import { HeaderContext } from "../HeaderContext";
import gsap from 'gsap';

const SideNavComponent = ({ routingLinks }) => {

    const [sideNavState, setSideNavState] = useContext(HeaderContext);

    

    useEffect(() => {
        if(sideNavState) {
            gsap.to(".sidenav-component", {duration: .15, autoAlpha: 1});
        } else {
            gsap.to(".sidenav-component", {duration: .15, autoAlpha: 0});
        }
    }, [sideNavState])

    return <ul className={`sidenav-component ${sideNavState ? "sidenav-component--visible" : "sidenav-component--not-visible"}`}>
    {
        routingLinks.map(route => (
            <li className="sidenav-component__link-container" key={route.name}>
                <NavLink onClick={() => setSideNavState(false)} exact to={route.path} activeClassName="sidenav-component__link--selected" className="sidenav-component__link font-color-light font-seconds">
                    {route.name}
                </NavLink>
            </li>
        ))
    }
</ul>
}
 
export default SideNavComponent;