import LOGOS from "../../../../assets/logos/index";
import PROFILES from "../../../../assets/profiles/index";

const Sander = {
    name: "Sander / Koiyaroid",
    position: "Artista",
    image1: PROFILES.Sander1,
    image2: PROFILES.Sander2,
    socialNetworks: [
        {url: "https://www.youtube.com/channel/UCNqOW7f1zngQlSj5FwriTDg", name: "Youtube", image: LOGOS.youtube},
        {url: "https://www.instagram.com/koiyaroid/", name: "Instagram", image: LOGOS.instagram},
        {url: "https://twitter.com/SanderTheCat", name: "Twitter", image: LOGOS.twitter},
    ],
    otherNames: "Sander // Koiyaroid",
    otherPositions: "Directora de arte / Artista principal",
    biography: [
        "Koiyaroid es una dibujante y animadora independiente, inicia su carrera de forma autodidacta desde muy pequeña, formalizando su oficio como freelancer a partir de los 18 años.",
        "Su trabajo destaca por el buen manejo de la estructura y expresión en cada una de sus obras siendo sus mayores influencias las producciones de Disney de los inicios de los 30's, 40's y las obras de Osamu Tezuka.",
        "Ha estado a cargo de la ilustración de la portada de la primera edición del conbook de Confuror y en su segunda edición estuvo a cargo de las ilustraciones publicitarias del mismo evento.",
    ],
    roleSubtitle: "Su rol en Forgotten Seconds:",
    role: [
        "Sander ya se encontraba trabajando de forma independiente para el proyecto desde sus inicios en 2017, pero oficialmente se hace parte del equipo creativo y directivo de Forgotten Seconds a partir del 2021, luego de sentir gran afinidad con el proyecto y creer en él.",
        "Se le otorga el título de Artista principal, pero la verdad es que ella hace más que eso, en el manga se encarga de revisar y pasar a modelo cada uno de los personajes a lo largo del proceso de 'name' además de diseñar y re-diseñar personajes, proponer creativamente y asistir con ajustes de diálogos en español para que todo se sienta más natural.",
        "También realiza ilustraciones originales para merchandise, publicaciones, asistencia de dirección de voces y participa en las piezas animadas del proyecto como Directora de arte y animadora líder.",
    ],
}

export default Sander;