import React, { useEffect } from 'react';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';
import { Link } from 'react-router-dom';
import LyricSlider from './components/LyricSlider';
import ImageSlider from './components/ImageSlider';
import useLanguage from '../../hooks/useLanguage';
import TEXT from "./lang/index";

import gsap from 'gsap';
import LOGOS from "../../assets/logos/index";

import "./LyricsPage.sass";
import lyricsLanguages from './lyrics';

const LyricsPage = () => {

    let translatedText = useLanguage(TEXT).translatedText; //Custom Hook
    let languageUsed = useLanguage(TEXT).pathname;

    useEffect(() => {
        //Fade In Transition effect
        gsap.set(".lyrics-page", {opacity: 0})
        gsap.to(".lyrics-page", {duration: 1, delay: .3, opacity: 1});
        window.scrollTo({
            top: 0,
        });
    }, [])

    return  <div className="lyrics-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="lyrics-page__container">
            <TitleComponent title={ translatedText.content.mainTitle } lang={languageUsed} />
            <div className="lyrics-page__wrapper bg-color-lighter-dark">
                <div className="lyrics-page__title">
                    <h1 className='lyrics-page__title font-seconds bg-color-light'>New Everything</h1>
                </div>
                <div className="lyrics-page__album-cover bg-color-light">
                    <ImageSlider/>
                </div>
                <Link to={"/"+languageUsed+"/music"} className="lyrics-page__title">
                    <h1 className={`lyrics-page__title font-seconds bg-color-light ${languageUsed === "jp" ? "jp-font" : ""}`}>{translatedText.content.button}</h1>
                </Link>

                <LyricSlider slides={lyricsLanguages} titles={translatedText.content.titles} />

                <div className="lyrics-page__music-logos">
                    <a href="https://www.youtube.com/c/ForgottenSeconds"><img src={LOGOS.youtubeBig} alt="Forgotten Seconds music on youtube" /></a>
                    <a href="https://open.spotify.com/artist/2lGvrBCW28CCilwfaCkBpp"><img src={LOGOS.spotifyBig} alt="Forgotten Seconds music on spotify" /></a>
                </div>
            </div>
        </div>
    </div>
}
 
export default LyricsPage;