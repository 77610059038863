import React from 'react';
import { Link } from 'react-router-dom';

import TEXT from "./lang/index";
import useLanguage from '../../hooks/useLanguage';

const DonationsComponent = () => {

    let translatedLink = useLanguage(TEXT).translatedText; //Custom Hook

    return <div className="donations-component">
        <Link to={translatedLink.content.url}>
            <img src={translatedLink.content.button} alt="Donations Button" width="166px" height="35px"/>
        </Link>
    </div>
}
 
export default DonationsComponent;