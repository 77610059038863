import React, { useEffect } from 'react';

import "./ReadMangaPage.sass";

import logoWebToon from  "../../assets/readmanga/webtoon.webp";
import logoPixiv from  "../../assets/readmanga/pixiv.webp";
import logoArtStreet from  "../../assets/readmanga/artstreet.webp";
import gsap from 'gsap';

import TEXT from "./lang/index";
import useLanguage from '../../hooks/useLanguage';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';

const ReadMangaPage = () => {

    useEffect(() => {
        gsap.set(".read-manga-page", {opacity: 0})
        gsap.to(".read-manga-page", {duration: 1, delay: .3, opacity: 1});

        window.scrollTo({
            top: 0,
        });

    }, [])

    let translatedText = useLanguage(TEXT).translatedText;

    return <div className="read-manga-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="read-manga-page__block-container">
            <TitleComponent title={ translatedText.content.title } lang={useLanguage(TEXT).pathname} />
            <div className="read-manga-page__block-wrapper bg-color-lighter-dark">
                <p className="read-manga-page__title font-color-light font-seconds"> {translatedText.content.subtitle} </p>
                <div className="read-manga-page__logos">
                    <div className="read-manga-page__logo" /*href={translatedText.content.links.webToon}*/>
                        <img className="read-manga-page__logo-webtoon" src={logoWebToon} alt="WebToon Logo" />
                    </div>
                    <div className="read-manga-page__logo" /*href={translatedText.content.links.pixiv}*/>
                        <img className="read-manga-page__logo-pixiv" src={logoPixiv} alt="Pixiv Logo" />
                    </div>
                    <div className="read-manga-page__logo" /*href={translatedText.content.links.artStreet}*/>
                        <img className="read-manga-page__logo-artstreet" src={logoArtStreet} alt="ArtStreet Logo" />
                    </div>
                </div>
            </div>
        </div>

    </div>
}
 
export default ReadMangaPage;