import MAP from "../../../../assets/map/index";

const contentEN = {
    title: "TEASER",
    videoURL: "y9ZRwRENUxI",
    synopsisComponent: {
        title: "What's the story about?",
        description: [
            "Forgotten Seconds is a story about two husky brothers Teru (19) and Kai (23) who haven't seen each other for years and now will have to learn to embrace their differences in order to overcome their personal conflicts.",
            "A story about personal growth, introspection, and the exploration of one’s identity. (LGBT)",
            // 'Things turn to worst when Teru (18), his younger brother who he hasn’t seen since they were kids, appears unexpectedly at his doorstep. His arrival brings great conflicts to Kai, since he represents a piece of his past he would rather leave behind, even though Teru and him used to be really close.',
            // 'Teru, on the other hand, must deal with Kai’s attitude, perform in his studies and face a new everything.'
        ]
    },
    helpComponent: {
        title: "WE ARE ONLY TWO ARTISTS, WE NEED YOUR HELP!",
        button: {
            url: "/en/support",
            image: MAP.eng
        }
    }
}

export default contentEN;