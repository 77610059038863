import './App.sass';
import HeaderComponent from "./components/header/HeaderComponent";
import HomePage from "./pages/home/HomePage";
import AboutPage from './pages/about/AboutPage';
import ReadMangaPage from "./pages/read/ReadMangaPage";
import OurTeamPage from "./pages/team/OurTeamPage";
import ContactPage from './pages/contact/ContactPage';
import FooterComponent from "./components/footer/FooterComponent";
import MusicPage from "./pages/music/MusicPage";
import CharactersPage from "./pages/characters/CharactersPage";
import SupportPage from "./pages/support/SupportPage";
import LyricsPage from "./pages/lyrics/LyricsPage";
import ProfilePage from './pages/profile/ProfilePage';
import ExclusivePage from './pages/exclusive/ExclusivePage';


import THUMBNAIL from "./assets/MINIATURAWEB2023.png";

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { useEffect } from 'react';
// import getPathname from './functions/getPathname';


const serverMeta = require("./hostname.json");

function App() {

  const profiles = [
    "/en/about/kylie", "/es/about/kylie", "/jp/about/kylie",
    "/en/about/sander", "/es/about/sander", "/jp/about/sander",
    "/en/about/nive_e", "/es/about/nive_e", "/jp/about/nive_e",
  ];

  return (
    <BrowserRouter>

      <HelmetProvider>
        <Helmet>
          <meta property="og:image" content={serverMeta.hostname + THUMBNAIL}/>
          <meta name="twitter:image" content={serverMeta.hostname + THUMBNAIL}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image:alt" content="Two anthropomorfical animal characters from the upcoming manga Forgotten Seconds"/>
        </Helmet>
        <div className="App">
          <HeaderComponent />

          <Switch>
            
            <Route path={["/en/contact", "/es/contact", "/jp/contact"]} component={ContactPage} />
            <Route path={["/en/support", "/es/support", "/jp/support"]} component={SupportPage} />
            <Route path={["/en/characters", "/es/characters", "/jp/characters"]} component={CharactersPage} />
            <Route path={["/en/music/lyrics", "/es/music/lyrics", "/jp/music/lyrics"]} component={LyricsPage} />
            <Route path={["/en/music", "/es/music", "/jp/music"]} component={MusicPage} />
            <Route path={profiles} component={ProfilePage} />
            <Route path={["/en/team", "/es/team", "/jp/team"]} component={OurTeamPage} />
            <Route path={["/en/read", "/es/read", "/jp/read"]} component={ReadMangaPage} />
            <Route path={["/en/about", "/es/about", "/jp/about"]} component={AboutPage} />
            <Route path={["/en/exclusive", "/es/exclusive", "/jp/exclusive"]} component={ExclusivePage} />
            <Route path={["/en", "/es", "/jp"]} component={HomePage} />
            <Route path="/" component={HomePage}>
              <Redirect to="/en" />
            </Route>

          </Switch>

          <FooterComponent />
        </div>
      </HelmetProvider>

    </BrowserRouter>
  );
}

export default App;
