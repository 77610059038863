const contentJP = {
    lang: "jp",
    routingLinks: [
        {
            name: "ホーム",
            path: "/jp"
        }, {
            name: "マンガを読む",
            path: "/jp/read"
        }, {
            name: "プロジェクト",
            path: "/jp/about"
        }, {
            name: "キャラクター",
            path: "/jp/characters"
        }, {
            name: "音楽",
            path: "/jp/music"
        }, {
            name: "支援する",
            path: "/jp/support"
        }, {
            name: "連絡先",
            path: "/jp/contact"
        }
    ],
}

export default contentJP;