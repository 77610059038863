import React, { useEffect } from 'react';

import gsap from 'gsap';

import "./AboutPage.sass";

import TEXT from "./lang/index";
import useLanguage from '../../hooks/useLanguage';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';
import OurTeamComponent from './component/team/OurTeamComponent';

const AboutPage = () => {

    let translatedText = useLanguage(TEXT).translatedText; //Custom Hook

    useEffect(() => {
        //Fade In Transition effect
        gsap.set(".about-page", {opacity: 0})
        gsap.to(".about-page", {duration: 1, delay: .3, opacity: 1});
    }, [])

    return  <div className="about-page">
        <MetatagsComponent metadata={translatedText.metatags} />


        <div className="about-page__text-container">
            <TitleComponent title={ translatedText.content.title } lang={useLanguage(TEXT).pathname} />
            <div className="about-page__text-wrapper bg-color-lighter-light">
                {
                    translatedText.content.description.map((paragraph, i) => (
                        <p className="about-page__paragraph font-body" key={i}>
                            {paragraph}
                        </p>
                    ))
                }
            </div>
            
        </div>
        <OurTeamComponent />
    </div>
}
 
export default AboutPage;