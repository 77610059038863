import React, { useEffect } from 'react';
import "./ProfilePage.sass";
import TitleComponent from '../../components/title/TitleComponent';
import SocialComponent from './components/SocialComponent';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';

import TEXT from "./lang/index";

import gsap from 'gsap';

import { useLocation } from "react-router-dom";
import useLanguage from '../../hooks/useLanguage';

const ProfilePage = () => {

    let location = useLocation();

    let text = useLanguage(TEXT).translatedText;
    
    const recognizeProfileFromLocation = () => {
        let pathname = location.pathname;
        pathname.indexOf(1);
        pathname.toLowerCase();
        pathname = pathname.split("/");
        pathname = pathname.pop();

        if(pathname === "" || pathname === null || pathname === isNaN) {
            return text.content.Kylie
        } else if(pathname === "kylie" || pathname === "Kylie" || pathname === "Dracani") {
            return text.content.Kylie
        } else if(pathname === "nive_e" || pathname === "Nive_e" || pathname === "Nive") {
            return text.content.Nive
        }   else if(pathname === "sander" || pathname === "Sander" || pathname === "Kate") {
            return text.content.Sander
        } else {
            return text.content.Kylie
        }
    }
    
    useEffect(() => {
        gsap.set(".profile-page", {opacity: 0})
        gsap.to(".profile-page", {duration: 1, delay: .3, opacity: 1});

        window.scrollTo({
            top: 0,
        });
    }, [])
    
    let translatedText = recognizeProfileFromLocation();

    let metadata = {
        title: "FORGOTTEN SECONDS | "+translatedText.name,
        description: translatedText.biography[0],
        url: text.metatags.url + translatedText.name
    }
    

    return <div className="profile-page">
        <MetatagsComponent metadata={metadata} />
        <div className="profile-page__container">
            <TitleComponent title={text.content.title} lang={useLanguage(TEXT).pathname} />
            <div className="profile-page__wrapper">
                <div className="profile-page__top-info">

                    <div className="profile-page__top-info-wrapper">
                        <img className="profile-page__profile-picture" src={translatedText.image1} alt="Primary" />
                        <div className="profile-page__top-info-text">
                            <div className="profile-page__name bg-color-lighter-light">
                                <p className="font-seconds">{translatedText.name}</p>
                            </div>
                            <p className="profile-page__position font-body">{translatedText.position}</p>
                        </div>
                    </div>

                    <img className="profile-page__secondary-image" src={translatedText.image2} alt="Secondary" />

                </div>
                <div className="profile-page__text-wrapper bg-color-lighter-light font-body">

                    <div className="profile-page__text-top">
                        <div className="profile-page__networks">
                            <SocialComponent networks={translatedText.socialNetworks} />
                        </div>
                        <div className="profile-page__secondary-info">
                            <p>{translatedText.otherNames}</p>
                            <p>{translatedText.otherPositions}</p>
                        </div>
                    </div>
                    
                    {
                        translatedText.biography.map((paragraph, i) => (
                            <p className="font-color-dark" key={i}>
                                { paragraph }
                            </p>
                        ))
                    }

                    <br/>
                    <p className="profile-page__role">{translatedText.roleSubtitle}</p>
                    
                    {
                        translatedText.role.map((paragraph, i) => (
                            <p className="font-color-dark" key={i}>
                                { paragraph }
                            </p>
                        ))
                    }

                    <div className="profile-page__mobile-container">
                        <div className="profile-page__mobile-networks">
                            <SocialComponent networks={translatedText.socialNetworks} />
                        </div>
                        <img className="profile-page__mobile-secondary" src={translatedText.image2} alt="Secondary" />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
 
export default ProfilePage;