import React, { useState } from 'react';
import { HeaderContext } from "./HeaderContext";

import "./HeaderComponent.sass";
import Logo from "../../assets/header/logobig.gif";

import NavComponent from "./nav/NavComponent";
import LangPicker from "./lang-picker/LangPicker";
import BurgerMenu from "./burger-menu/BurgerMenu";
import SideNavComponent from './sidenav/SideNavComponent';
import SupportComponent from "../../components/support/SupportComponent";

import TEXT from "./lang/index";

import useLanguage from '../../hooks/useLanguage';


const HeaderComponent = () => {

    const [sideNavState, setSideNavState] = useState(false);

    let translatedText = useLanguage(TEXT).translatedText;
    let languageUsed = useLanguage(TEXT).pathname;
    

    return <HeaderContext.Provider value={[sideNavState, setSideNavState]}>
        <div className="header-component">
            <SideNavComponent routingLinks={translatedText.content.routingLinks} />
            <BurgerMenu />

            <div className="header-component__background">
                <div></div>
                <div></div>
            </div>

            <div className="header-component__logo-side">
                <img src={Logo} alt="Logo" />
                <div className="header-component__background-purple"></div>
            </div>
            <div className="header-component__navigation-side">
                <div className="header-component__navigation-container">
                    <NavComponent routingLinks={translatedText.content.routingLinks} lang={languageUsed} />
                    <div className="header-component__horizontal-buttons">
                        <div className="header-component__lang-picker-mobile">
                            <LangPicker />
                        </div>
                        <SupportComponent />
                    </div>
                </div>
                <div className="header-component__background-purple"></div>
            </div>
            <div className="header-component__buttons">
                <LangPicker />
                <SupportComponent />
            </div>
        </div>
    </HeaderContext.Provider>
}
 
export default HeaderComponent;