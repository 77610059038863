import MUSIC from "../../../../assets/music/index";

const contentEN = {
    title: "MUSIC",
    subtitle: "COMING SOON AT:",
    links: {
        youtube: "/",
        spotify: "/",
    },
    title2: "PLAY",
    songTitle: '"New Everything"',
    albumCover: MUSIC.eng,
    globo: {
        img: MUSIC.globoENG,
        url: "/"
    },
    buttons: {
        video: {
            text: "Watch videos",
            url: "/"
        },
        lyrics: {
            text: "Lyrics"
        }
    },
    textInfo: [
        "Original song that represents the whole project, it also represents Teru's feelings.",
        "composer: KAMIGT.",
        "Lyrics by: Inger Marinkovic.",
        "Japanese lyrics adaptation by: Casual Toast.",
        "\n",
        "Available in three laguages + multilanguage",
        "English- Caleb Hyles",
        "Japanese- Casual Toast",
        "Spanish- César franco",
        "\n",
        "Realesed: 08/27/2021"
    ],
}

export default contentEN;