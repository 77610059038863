import React, { useEffect } from 'react';
import ProfileComponent from "./profile/ProfileComponent";
import { Link } from "react-router-dom";

import "./OurTeamPage.sass";
import TEXT from "./lang/index";

import gsap from 'gsap';
import useLanguage from '../../hooks/useLanguage';
import MetatagsComponent from '../../components/metatags/MetatagsComponent';
import TitleComponent from '../../components/title/TitleComponent';

const OurTeamPage = () => {

    useEffect(() => {
        gsap.set(".our-team-page", {opacity: 0})
        gsap.to(".our-team-page", {duration: 1, delay: .3, opacity: 1});

        window.scrollTo({
            top: 0,
        });
    }, [])

    let translatedText = useLanguage(TEXT).translatedText;

    return <div className="our-team-page">
        <MetatagsComponent metadata={translatedText.metatags} />
        <div className="our-team-page__team-container font-forgotten">

            <div className="our-team-page__team-wrapper bg-color-darker-light">
                <TitleComponent title={translatedText.content.mainTeam.title} lang={useLanguage(TEXT).pathname} />

                <img className="our-team-page__main-image" src={translatedText.content.mainTeam.mainImage} width="600px" alt="Forgotten Second's main team working" />

                <div className="our-team-page__profile-wrapper">
                    {
                        translatedText.content.mainTeam.members.map((member, i) => (
                            <Link to={member.url} key={i} >
                                <ProfileComponent image={member.picture} name={member.name} description={member.description} url={member.url} team="main"  />
                            </Link>
                        ))
                    }
                </div>
            </div>

            <div className="our-team-page__team-wrapper bg-color-darker-light">
                <TitleComponent title={translatedText.content.languageTeam.title} lang={useLanguage(TEXT).pathname} />
                <div className="our-team-page__profile-wrapper">
                    {
                        translatedText.content.languageTeam.members.map((member, i) => (
                            <ProfileComponent key={i} image={member.picture} name={member.name} description={member.description} url={member.url} team="language" />
                        ))
                    }
                </div>
            </div>
            
        </div>

    </div>
}
 
export default OurTeamPage;