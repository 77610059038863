import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import MUSIC from "../../../assets/music/index";

const ImageSlider = ({slides, titles}) => {

    //const images = [MUSIC.eng, MUSIC.esp, MUSIC.jpn]
    const handleDragStart = (e) => e.preventDefault();

    const items = [
      <img src={MUSIC.eng} style={{"width": "100%"}} onDragStart={handleDragStart} role="presentation" alt="Album Cover English" />,
      <img src={MUSIC.esp} style={{"width": "100%"}} onDragStart={handleDragStart} role="presentation" alt="Album Cover Spanish" />,
      <img src={MUSIC.jpn} style={{"width": "100%"}} onDragStart={handleDragStart} role="presentation" alt="Album Cover Japanese" />,
    ];

    return <AliceCarousel mouseTracking items={items} infinite={true} disableButtonsControls={true} disableSlideInfo={true} autoPlay={true} autoPlayInterval={2000} />
}
 
export default ImageSlider;