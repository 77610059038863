const PROFILES = {
    Kylie: require("./kylie.webp").default,
    Nive_e: require("./nive_e.webp").default,
    Kate: require("./kate.webp").default,
    Maho: require("./maho.webp").default,
    Brian: require("./brian.webp").default,
    Team: require("./team.webp").default,
    NewKate: require("./newsander.webp").default,
    NewKylie: require("./newkylie.webp").default,
    NewNive_e: require("./newnive.webp").default,
    NewMaho: require("./newcenteredmaho.png").default,
    NewBrian: require("./newcenteredbrian.png").default,
    KylieFull: require("./kai1.webp").default,
    SanderFull: require("./koi1.webp").default,
}

export default PROFILES;