import MUSIC from "../../../../assets/music/index";

const contentES = {
    title: "MÚSICA",
    subtitle: "PRÓXIMAMENTE:",
    links: {
        youtube: "/",
        spotify: "/",
    },
    title2: "PLAY",
    songTitle: '"Todo a la vez"',
    albumCover: MUSIC.esp,
    globo: {
        img: MUSIC.globoESP,
        url: "/"
    },
    buttons: {
        video: {
            text: "Ver videos",
            url: "/"
        },
        lyrics: {
            text: "Letra canción"
        }
    },
    textInfo: [
        "Canción original que representa a todo el proyecto que además, representa los sentimientos de Teru.",
        "Compuesta por: KAMIGT.",
        "letra por: Inger Marinkovic.",
        "Adaptación al japonés: Casual Toast.",
        "\n",
        "Disponible en 3 idiomas + multilenguaje",
        "Inglés - Caleb Hyles",
        "Japonés - Casual Toast",
        "Español - César Franco",
        "\n",
        "Estreno: 27/08/2021"
    ],
}

export default contentES;