import PROFILES from "../../../../../../assets/team/index";

const contentEN = {
    mainTeam: {
        title: "MAIN TEAM",
        mainImage: PROFILES.Team,
        members:[
            {
                picture: PROFILES.KylieFull,
                name: "Kylie Dracani",
                url: "/en/about/Kylie",
                description: ["Creator, producer and more"]
            },
            {
                picture: PROFILES.SanderFull,
                name: "KOIYAROID",
                url: "/en/about/Sander",
                description: ["Main artist and more"]
            }
        ]
    },
    languageTeam: {
        title: "LANGUAGE TEAM",
        members: [
            {
                picture: PROFILES.NewBrian,
                name: "Spanish Texts",
                url: "/",
                description: ["DRACANI", "KOIYAROID"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "English Translations",
                url: "/",
                description: ["DRACANI", "KOIYAROID", "BRIAN JOHNSON", "RODRIGO TRUPP", "CASUAL TOAST"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "Japanese Translations",
                url: "/",
                description: ["SEKKI WOLF"]
            },
        ]
    }
}

export default contentEN;