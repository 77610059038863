import React from 'react';

import "./ProfileComponent.sass";

const ProfileComponent = ({name, description, url, team}) => {
    return <div className={`profile-component ${"profile-component--"+team}`}>
        <div className="profile-component__text-wrapper bg-color-lighter-light">
            <p className={`profile-component__text-name ${(team==="language") ? "font-body font-size-sm weight-normal" : "font-seconds font-size-l weight-bold"}`}>{name}</p>
            <div className={`profile-component__text-description ${(team==="language") ? "font-seconds font-size-l weight-bold" : "font-body font-size-sm weight-normal"}`}>
                {
                    description.map((paragraph, i) => (
                        <p key={i} >{paragraph}</p>
                    ))
                }
            </div>
        </div>
    </div>
}

export default ProfileComponent;