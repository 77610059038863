import React, { useContext } from 'react';
import "./BurgerMenu.sass";

import { HeaderContext } from "../HeaderContext";

const BurgerMenu = () => {

    const [sideNavState, setSideNavState] = useContext(HeaderContext);

    return <div onClick={() => setSideNavState(!sideNavState)} className="burger-menu-component">
        <div></div>
        <div></div>
        <div></div>
    </div>
}
 
export default BurgerMenu;