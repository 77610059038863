import PROFILES from "../../../../../../assets/team/index";

const contentES = {
    mainTeam: {
        title: "EQUIPO PRINCIPAL",
        mainImage: PROFILES.Team,
        members:[
            {
                picture: PROFILES.KylieFull,
                name: "Kylie Dracani",
                url: "/es/about/Kylie",
                description: ["Directora del proyecto"]
            },
            {
                picture: PROFILES.SanderFull,
                name: "Kate Sander",
                url: "/es/about/Sander",
                description: ["Directora de arte"]
            }
        ]
    },
    languageTeam: {
        title: "EQUIPO DE LENGUAJE",
        members: [
            {
                picture: PROFILES.NewBrian,
                name: "Textos en español",
                url: "/",
                description: ["DRACANI", "KOIYAROID"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "Traducción al inglés",
                url: "/",
                description: ["DRACANI", "KOIYAROID", "BRIAN JOHNSON", "RODRIGO TRUPP", "CASUAL TOAST"]
            },
            {
                picture: PROFILES.NewMaho,
                name: "Traducción en Japonés",
                url: "/",
                description: ["SEKKI WOLF"]
            },
        ]
    }
}

export default contentES;